<template>
  <div class="program-container">
    <!-- Hero -->
    <hero :content="article" :breadcrumb-items="items" />

    <!-- Section Tag -->
    <section class="container my-2">
      <b-badge
        v-for="tag in article.tags"
        :key="tag"
        :variant="`light-${tag.color}`"
        class="mt-1 mb-3 mr-1"
      >
        {{ tag.name }}
      </b-badge>

      <div class="content">
        <!-- DUMMY CONTENT -->
        <img
          class="w-100 rounded"
          src="https://picsum.photos/1200/800"
          alt=""
        />

        <p class="mt-1">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
          ullamcorper mattis lorem non. Ultrices praesent amet ipsum justo
          massa. Eu dolor aliquet risus gravida nunc at feugiat consequat purus.
          Non massa enim vitae duis mattis. Vel in ultricies vel fringilla.
          Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla
          odio nisl vitae. In aliquet pellentesque aenean hac vestibulum turpis
          mi bibendum diam. Tempor integer aliquam in vitae malesuada fringilla.
          Elit nisi in eleifend sed nisi. Pulvinar at orci, proin imperdiet
          commodo consectetur convallis risus. Sed condimentum enim dignissim
          adipiscing faucibus consequat, urna. Viverra purus et erat auctor
          aliquam. Risus, volutpat vulputate posuere purus sit congue convallis
          aliquet. Arcu id augue ut feugiat donec porttitor neque. Mauris, neque
          ultricies eu vestibulum, bibendum quam lorem id. Dolor lacus, eget
          nunc lectus in tellus, pharetra, porttitor. Ipsum sit mattis nulla
          quam nulla. Gravida id gravida ac enim mauris id. Non pellentesque
          congue eget consectetur turpis. Sapien, dictum molestie sem tempor.
          Diam elit, orci, tincidunt aenean tempus. Quis velit eget ut tortor
          tellus. Sed vel, congue felis elit erat nam nibh orci.
        </p>
      </div>

      <div class="author d-flex justify-content-between border-top-muted py-1">
        <div class="d-flex">
          <b-avatar size="lg" :src="article.author.avatar" />
          <div class="pl-2 d-flex flex-column justify-content-center">
            <h5 class="font-weight-bolder mb-0">
              {{ article.author.name }}
            </h5>
            <span class="font-weight-light text-muted">
              {{ article.author.role }}
            </span>
          </div>
        </div>
        <div>
          <b-button class="mr-1" variant="outline-dark">
            <i class="ti ti-copy" />
            <span class="font-weight-bolder"> Copy Link</span>
          </b-button>
          <b-button class="mr-1" variant="outline-dark">
            <i class="ti ti-brand-twitter" />
          </b-button>
          <b-button class="mr-1" variant="outline-dark">
            <i class="ti ti-brand-facebook" />
          </b-button>
          <b-button class="mr-1" variant="outline-dark">
            <i class="ti ti-brand-linkedin" />
          </b-button>
        </div>
      </div>
    </section>

    <!-- Explore -->
    <explore />

    <!-- Footer -->
    <custom-footer />
  </div>
</template>

<script>
import { BRow, BCol, BButton, BAvatar, BBadge } from 'bootstrap-vue'

// Custom Components
import Explore from '@/views/tekov/landingpage/components/Explore.vue'
import CustomFooter from '@/views/tekov/landingpage/components/Footer.vue'
import Hero from './components/Hero.vue'

export default {
  components: {
    BRow,
    BCol,
    BAvatar,
    BButton,
    BBadge,

    Hero,
    Explore,
    CustomFooter,
  },
  data() {
    return {
      items: [
        {
          text: 'Home',
          to: '/',
        },
        {
          text: 'Kelas AR (Augmented Reality)',
          active: true,
        },
        {
          text: 'Detail',
          active: true,
        },
      ],

      article: {
        title: 'Kelas AR (Augmented Reality)',
        desc:
          'How do you create compelling presentations that wow your colleagues and impress your managers?',
        tags: [
          {
            name: 'Augmented Reality',
            color: 'primary',
          },
          {
            name: 'Design',
            color: 'success',
          },
        ],
        content: `

        `,
        created_at: '2020-10-10',
        author: {
          name: 'Olivia Rhye',
          role: 'UI/UX Designer',
          avatar: 'https://picsum.photos/200',
          socials: {
            twitter: 'https://twitter.com',
            facebook: 'https://facebook.com',
            linkedin: 'https://linkedin.com',
          },
        },
      },
    }
  },
}
</script>

<style lang="scss">
.program-container {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 100px !important;
}

.list-padding {
  padding: 0.4rem 0;
}

.offset-mt {
  margin-top: -18rem;
}

.clean-paragraph {
  text-justify: inter-word;
  line-height: 26px;
}

.border-top-muted {
  border-top: 1px solid #e9ecef;
}
</style>
