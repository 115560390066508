<template>
  <section class="hero text-white">
    <div class="hero-body">
      <div class="container">
        <b-row class="py-2">
          <b-col>
            <b-breadcrumb
              class="breadcrumb-chevron mb-0 breadcrumb-white py-2 px-0"
              :items="breadcrumbItems"
            />
            <span>Published at {{ formatDate(content.created_at) }}</span>
            <h1 class="title text-white py-1">{{ content.title }}</h1>
            <p>{{ content.desc }}</p>
          </b-col>
        </b-row>
      </div>
    </div>
  </section>
</template>

<script>
import { BRow, BCol, BButton, BBreadcrumb } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BBreadcrumb,
  },
  props: {
    breadcrumbItems: {
      type: Array,
      default: () => [],
    },
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    formatDate(date) {
      const options = { year: 'numeric', month: 'long', day: 'numeric' }
      return new Date(date).toLocaleDateString('id-ID', options)
    },
  },
}
</script>

<style lang="scss">
.hero {
  background: linear-gradient(63.44deg, #1d2939 16.72%, #475467 83.39%);
}

.breadcrumb-white li {
  a {
    color: white !important;
  }
  color: white !important;
}
</style>
