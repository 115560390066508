<template>
  <section class="py-1 py-md-4 d-flex bg-secondary padding-x">
    <div class="w-90 w-md-70">
      <h1 class="pl-1 pl-md-4 py-1 py-md-2 big-text font-weight-bolder text-white">
        Join with us now to explore class and make your dreams come true
      </h1>
    </div>
    <div class="w-30 d-md-flex flex-row align-items-center justify-content-center">
      <b-button
        class="bg-white"
        variant="flat-secondary"
        target="_blank"
        href="https://wa.me/6282112882656"
      >
        <i class="ti ti-message-circle-2" />
        Hubungi Sales
      </b-button>
      <b-button
        class="bg-white ml-2 mt-2 mt-md-0"
        variant="flat-secondary"
        target="_blank"
        href="https://instagram.com/tekov_official?igshid=OGQ5ZDc2ODk2ZA=="
      >
        <i class="ti ti-brand-instagram" />
        Ikuti kami
      </b-button>
    </div>
  </section>
</template>

<script>
import { BButton } from 'bootstrap-vue'

export default {
  components: {
    BButton,
  },
}
</script>

<style>
.w-30 {
  width: 30%;
}

.w-70 {
  width: 70%;
}

.padding-x {
  padding: 0 5%;
}
</style>
